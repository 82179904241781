<template>
  <div>
    <ul class="activity-ul" v-if="listType == 'activity'">
      <li
        class="activity-li"
        v-for="(item, index) in list"
        :key="'activity' + index"
      >
        <div class="listState" :class="`current${item.status}`">
          <div class="listStateFont" v-if="item.status == 1">已结束</div>
          <div class="listStateFont" v-else-if="item.status == 2">进行中</div>
          <div class="listStateFont" v-else-if="item.status == 4">报名中</div>
        </div>
        <router-link :to="`/activity/detail?id=${item.id}`" target="_blank">
          <div class="activity-li-banner">
            <img style="width: 100%; height: 100%" :src="item.banner" alt="" />
          </div>
          <div class="activity-li-bottom">
            <div class="activity-li-bottom-title">
              {{ item.title }}
            </div>
            <div class="activity-li-bottom-des">
              <img
                class="activity-li-bottom-icon"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_address_icon.png"
                alt=""
              />
              <div class="activity-li-bottom-icon-title">
                {{ item.address }}
              </div>
            </div>
            <div class="activity-li-bottom-des">
              <img
                class="activity-li-bottom-icon"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_date_icon.png"
                alt=""
              />
              <div class="activity-li-bottom-icon-title">
                {{ item.active_start_time }} ~ {{ item.active_end_time }}
              </div>
            </div>
          </div>
        </router-link>
        <img
          class="activity-li-selected"
          src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
          alt=""
        />
      </li>
    </ul>
    <ul class="activity-ul" v-if="listType == 'offline'">
      <li
          class="activity-li"
          v-for="(item, index) in list"
          :key="'activity' + index"
      >
        <div class="listState" :class="`current${item.status}`">
          <div class="listStateFont" v-if="item.status == 1">已结束</div>
          <div class="listStateFont" v-else-if="item.status == 2">进行中</div>
          <div class="listStateFont" v-else-if="item.status == 4">报名中</div>
        </div>
        <div @click="goBack(item)" target="_blank">
          <div class="activity-li-banner">
            <img style="width: 100%; height: 100%" :src="item.banner" alt="" />
          </div>
          <div class="activity-li-bottom">
            <div class="activity-li-bottom-title">
              {{ item.title }}
            </div>
            <div class="activity-li-bottom-des">
              <img
                  class="activity-li-bottom-icon"
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_address_icon.png"
                  alt=""
              />
              <div class="activity-li-bottom-icon-title">
                {{ item.address }}
              </div>
            </div>
            <div class="activity-li-bottom-des">
              <img
                  class="activity-li-bottom-icon"
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_date_icon.png"
                  alt=""
              />
              <div class="activity-li-bottom-icon-title">
                {{ item.active_start_time }} ~ {{ item.active_end_time }}
              </div>
            </div>
          </div>
        </div>
        <img
            class="activity-li-selected"
            src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
            alt=""
        />
      </li>
    </ul>
    <ul class="live-ul" v-if="listType == 'live'">
      <li
        class="live-ul-li"
        v-for="(item, index) in list"
        :key="'live' + index"
      >
        <div
          @click="goLiveDetail(item)" target="_blank"
        >
          <div class="live-ul-li-banner">
            <img style="width: 100%; height: 100%" :src="item.cover" alt="" />
            <div class="listState" :class="`current${item.status}`">
              <div class="listStateFont" v-if="item.status == 1">
                <img
                  class="live-ul-li-banner-icon"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2F%E7%BC%96%E7%BB%84%203.png"
                  alt=""/>
                正在直播</div>
              <div class="listStateFont" v-else-if="item.status == 2">直播预告</div>
              <div class="listStateFont" v-else-if="item.status == 3">精彩回放</div>
            </div>
            <!--<div class="numberState" v-if="item.status == 1">
              <img
                  class="live-ul-li-banner-number-icon"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2FClip%202.png"
                  alt=""/>
                  163257253
            </div>-->
            <div class="numberAfterState1" v-if="item.status == 3">
              <img
                  class="live-ul-li-banner-numberAfter-background"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2F%E7%9F%A9%E5%BD%A2%20(2).png"
                  alt=""/>
                  
            </div>
            <div class="numberAfterState" v-if="item.status == 3">
              <img
                  class="live-ul-li-banner-numberAfter-icon"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2FClip%202%20(1).png"
                  alt=""/>
                  {{item.playback_view_count}}
            </div>
          </div>
          <div class="live-ul-li-bottom">
            <div class="live-ul-li-bottom-title">{{ item.title }}</div>
            
            <div class="live-ul-li-bottom-des">
              <div class="live-ul-li-bottom-des-left">
                
                <img
                  class="activity-li-bottom-icon"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_live_time.png"
                  alt=""
                />
                <div class="activity-li-bottom-icon-title">
                  {{ item.start_time*1000 | dateFormat1 }}
                </div>
              </div>
              <div class="live-ul-li-bottom-des-right" v-if="item.status == 2">
                <div class="live-ul-li-bottom-des-right-title">
                  {{item.reserveCount}}人已预约
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </li>
    </ul>
    <ul class="culture-ul" v-if="listType == 'culture'">
      <li
        class="culture-li"
        v-for="(item, index) in list"
        :key="'culture' + index"
      >
        <div @click="goDetailCulture(item)" target="_blank">
          <div class="culture-li-banner">
            <img style="width: 100%; height: 100%" :src="item.cover" alt="" />
          </div>
          <div class="culture-li-bottom">
            <div class="culture-li-bottom-title">
              {{ item.title }}
            </div>
            <div class="culture-li-bottom-des">
              <img
                class="culture-li-bottom-icon"
                src="https://image.bookgo.com.cn/webculture/jm/detail/detail_culture_speaker.png"
                alt=""
              />
              <div class="culture-li-bottom-icon-title">
                授课老师：{{ item.speaker }}
              </div>
            </div>
            <div class="culture-li-bottom-des">
              <img
                class="culture-li-bottom-icon"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_date_icon.png"
                alt=""
              />
              <div class="culture-li-bottom-icon-title">
                上课时间：{{ item.start_date }} ~
                {{ item.end_date }}
              </div>
            </div>
            <div class="culture-li-bottom-des">
              <img
                class="culture-li-bottom-icon"
                src="https://image.bookgo.com.cn/webculture/jm/detail/detail_culture_chapter.png"
                alt=""
              />
              <div class="culture-li-bottom-icon-title">
                课时：{{ item.chapter_count }}节
              </div>
            </div>
          </div>
        </div>
        <img
          class="culture-li-selected"
          src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
          alt=""
        />
      </li>
    </ul>
    <ul class="intangible-ul" v-if="listType == 'intangible'">
      <li
        class="intangible-ul-li"
        v-for="(item, index) in list"
        :key="'intangible' + index"
      >
        <router-link
          :to="{ path: `/intangible/detail11?id=${item.id}` }"
          target="_blank"
        >
          <div class="intangible-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="intangible-ul-li-bottom">
            <div class="intangible-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </router-link>
      </li>
    </ul>
    <ul class="teachvideo-ul" v-if="listType == 'teachvideo'">
      <li
        class="teachvideo-ul-li"
        v-for="(item, index) in list"
        :key="'intangible' + index"
      >
        <router-link
          :to="{ path: `/teachvideo/edetail?id=${item.id}&name=${name}` }"
          target="_blank"
        >
          <div class="teachvideo-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="teachvideo-ul-li-bottom">
            <div class="teachvideo-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </router-link>
      </li>
    </ul>
    <ul class="resources-ul" v-if="listType == 'resources_e'">
      <li
        class="resources-ul-li"
        v-for="(item, index) in list"
        :key="'resource_e' + index"
      >
        <div
          @click="goIntangibleDetail(item)"
          v-if="class_id == 1"
        >
          <div class="resources-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="resources-ul-li-bottom">
            <div class="resources-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </div>
        <router-link
          :to="{ path: `/teachvideo/edetail?id=${item.id}&name=舞台艺术&navId=${navId}&cat_id=${cat_id}&class_id=${class_id}` }"
          target="_blank"
          v-if="class_id == 4"
        >
          <div class="resources-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="resources-ul-li-bottom">
            <div class="resources-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </router-link>
        <div
          @click="goDetailTeachVideo(item,name)"
          target="_blank"
          v-if="class_id == 3"
        >
          <div class="resources-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="resources-ul-li-bottom">
            <div class="resources-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </div>
        <div
          @click="goResourceEdetail(item)"
          target="_blank"
           v-if="class_id == 0||class_id == 5"
        >
          <div class="resources-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="resources-ul-li-bottom">
            <div class="resources-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </div>
        <router-link
          :to="{ path: `/resources/tdetail111?id=${item.id}` }"
          target="_blank"
           v-else
        >
          <div class="resources-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="resources-ul-li-bottom">
            <div class="resources-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </router-link>
      </li>
    </ul>
    <ul class="resources-t-ul" v-if="listType == 'resources_t'">
      <li
        class="resources-t-ul-li"
        v-for="(item, index) in list"
        :key="'resources_t' + index"
      >
        <div
          @click="goResourcesTdetail(item)"
        >
          <div class="resources-t-ul-li-banner">
            <img
              style="width: 100%; height: 100%"
              :src="item.main_pic"
              alt=""
            />
          </div>
          <div class="resources-t-ul-li-bottom">
            <div class="resources-ul-li-bottom-title" style="overflow: visible;-webkit-line-clamp: 2;font-size: 0.18rem;">{{ item.name }}</div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="volunteer-ul" v-if="listType == 'volunteer'">
      <li
        class="volunteer-ul-li"
        v-for="(item, index) in list"
        :key="'volunteer' + index"
      >
        <router-link
          :to="{ path: `/volunteer/detail?id=${item.id}` }"
          target="_blank"
        >
          <div class="volunteer-ul-li-banner">
            <img style="width: 100%; height: 100%" :src="item.cover" alt="" />
            <div class="volunteer-ul-li-banner-total">
              {{ item.team_total }}人
            </div>
          </div>
          <div class="volunteer-ul-li-bottom">
            <div class="volunteer-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </router-link>
      </li>
    </ul>
    <ul class="maplist-ul" v-if="listType == 'maplist'">
      <li
        class="maplist-ul-li"
        v-for="(item, index) in list"
        :key="'maplist' + index"
      >
        <router-link
          :to="{ path: `/map/detail?id=${item.id}` }"
          target="_blank"
        >
          <div class="maplist-ul-li-banner">
            <img style="width: 100%; height: 100%" :src="item.pic" alt="" />
            <div
              class="maplist-ul-li-banner-total"
              v-if="available_reserve == 1"
            >
              可预约
            </div>
          </div>
          <div class="maplist-ul-li-bottom">
            <div class="maplist-ul-li-bottom-title">{{ item.name }}</div>
          </div>
        </router-link>
        <img
          class="maplist-li-selected"
          src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
          alt=""
        />
      </li>
    </ul>
    <ul class="live-ul1" v-if="listType == 'home_live'">
      <li class="live-ul1-li" v-for="(item, index) in list" :key="index">
        <router-link
          :to="{ path: `/live/detail?id=${item.id}` }"
        >
          <div class="live-ul1-li-banner">
            <img style="width: 100%; height: 100%" :src="item.cover" alt="" />
          </div>
          <div class="live-ul1-li-bottom">
            <div class="live-ul1-li-bottom-title">{{ item.title }}</div>
            <div
              class="live-ul1-li-bottom-status live-status-bg1"
              v-if="item.status == 1"
            >
              正在直播
            </div>
            <div
              class="live-ul1-li-bottom-status live-status-bg2"
              v-if="item.status == 2"
            >
              直播预告
            </div>
            <div
              class="live-ul1-li-bottom-status live-status-bg2"
              v-if="item.status == 3"
            >
              精彩回顾
            </div>
            <div class="live-ul1-li-bottom-sponsor">
              主办单位：{{ item.sponsor }}
            </div>
            <div class="live-ul1-li-bottom-summary">
              直播简介：{{ item.content | deleteHtmlTag }}
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {formatUnixtimestamp} from '@/utils/help';
export default {
  data() {
    return {
      myClass:'test2222',
      name:this.$route.query.name,
    };
  },
  props: {
    listType: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: null,
    },
    class_id: {
      type: null,
      default: 0,
    },
    navId: {
      type: null,
      default: 0,
    },
    cat_id: {
      type: null,
      default: 0,
    },
    currentName:{
      type: null,
      default: "",
    }
  },
  computed:{
    classComputed:{
          get: function (val) {
            console.log(val)
            return 1
        }
    }
},
methods:{
  goBack(item) {
    console.log(item)
    let routeUrl = this.$router.resolve({
      path: `offlinetrain/detail`,
      query: { id:item.id,pid: this.$route.query.id,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.currentName},
    });
    window.open(routeUrl.href, "_blank");
  },
  goDetailCulture(item) {
    console.log(item)
    let routeUrl = this.$router.resolve({
      path: `cultural/detail`,
      query: { id:item.id,pid: this.$route.query.id,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.currentName},
    });
    window.open(routeUrl.href, "_blank");
  },
  goDetailTeachVideo(item) {
    console.log(item)
    let routeUrl = this.$router.resolve({
      path: `teachvideo/edetail`,
      query: { id:item.id,pid: this.$route.query.id,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:'教学视频'},
    });
    console.log(routeUrl)
    window.open(routeUrl.href, "_blank");
  },
  goLiveDetail(item) {
    console.log(item)
    let routeUrl = this.$router.resolve({
      path: `live/detail`,
      query: { id:item.id,pid: this.$route.query.id,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:'教学视频'},
    });
    console.log(routeUrl)
    window.open(routeUrl.href, "_blank");
  },
  goIntangibleDetail(item) {
    console.log(item)
    let routeUrl = this.$router.resolve({
      path: `intangible/detail`,
      query: { id:item.id,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.currentName},
    });
    console.log(routeUrl)
    window.open(routeUrl.href, "_blank");
  },
  goResourceEdetail(item) {
    console.log('--------------------------')
    let routeUrl = this.$router.resolve({
      path: `resources/edetail`,
      query: { id:item.id,pid: this.$route.query.id,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.currentName},
    });
    console.log(routeUrl)
    window.open(routeUrl.href, "_blank");
  },
  goResourcesTdetail(item) {
        let routeUrl = this.$router.resolve({
          path: `resources/tdetail`,
          query: { id:item.id,pid: this.$route.query.id,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.currentName},
        });
        console.log(routeUrl)
        window.open(routeUrl.href, "_blank");
  }
},
  mounted() {

  }
};
</script>

<style lang="scss">
@import "../../assets/scss/list_item.scss";
</style>
